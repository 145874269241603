import React from "react";
import { Col, Tag, Popover } from "antd";
import {
  monyRender,
  authTokenData,
  hostRender,
  utmTermRender,
  DashboardUtils,
  userCRMLinkRender,
} from "../DashboardUtils";

const dashboard_payments = [
  // Переключения тарифов
  // Выбор тарифов
  // Платежи страйпом
  //
  {
    lg: 12,
    id: 0,
    name: "Регистрации по месяцам",
    vizState: {
      query: {
        measures: ["ImSaasUsers.count"],
        timeDimensions: [
          {
            dimension: "ImSaasUsers.createdAt",
            granularity: "month",
            dateRange: "Last 365 days",
          },
        ],
        order: {},
      },
      chartType: "line",
    },
  },
];

if (authTokenData().u.id == 1) {
  dashboard_payments.push({
    id: 0,
    lg: 12,
    name: "Платежи за год",
    vizState: {
      query: {
        measures: ["ImBillingOrders.total"],
        timeDimensions: [
          {
            dimension: "ImBillingOrders.paid_at",
            granularity: "month",
            dateRange: "Last 365 days",
          },
        ],
        dimensions: [],
        filters: [
          { dimension: "ImBillingOrders.transaction_data", operator: "set" },
          {
            dimension: "ImSaasUsers.email",
            operator: "notEquals",
            values: DashboardUtils.demoAndEmptyEmails,
          },
        ],
        order: { "ImBillingOrders.paid_at": "desc" },
      },
      chartType: "line",
      opts: {
        dataMap: (data) => {
          return data;
        },
        columns: (data) => {
          return data;
        },
        dataSource: (data) => {
          return data;
        },
      },
    },
  });

  dashboard_payments.push({
    id: 0,
    lg: 8,
    name: "Привязка карт в страйп (За год)",
    vizState: {
      query: {
        measures: ["ImSaasUsers.count"],
        timeDimensions: [
          {
            dimension: "ImSaasUsers.createdAt",
            granularity: "month",
            dateRange: "Last 365 days",
          },
        ],
        filters: [
          {
            dimension: "ImSaasUsers.hasStripeId",
            operator: "equals",
            values: ["1"],
          },
          {
            dimension: "ImSaasUsers.email",
            operator: "notEquals",
            values: DashboardUtils.demoAndEmptyEmails,
          },
        ],
        dimensions: [],
        order: {
          "ImSaasUsers.createdAt": "asc",
        },
      },
      chartType: "line",
    },
  });

  dashboard_payments.push({
    id: 0,
    lg: 8,
    name: "Привязка карт в страйп (За 21 неделю)",
    vizState: {
      query: {
        measures: ["ImSaasUsers.count"],
        timeDimensions: [
          {
            dimension: "ImSaasUsers.createdAt",
            granularity: "week",
            dateRange: "Last 180 days",
          },
        ],
        filters: [
          {
            dimension: "ImSaasUsers.hasStripeId",
            operator: "equals",
            values: ["1"],
          },
          {
            dimension: "ImSaasUsers.email",
            operator: "notEquals",
            values: DashboardUtils.demoAndEmptyEmails,
          },
        ],
        dimensions: [],
        order: {
          "ImSaasUsers.createdAt": "asc",
        },
      },
      chartType: "line",
    },
  });
  dashboard_payments.push({
    id: 0,
    lg: 8,
    name: "Привязка карт в страйп (За 30 дней)",
    vizState: {
      query: {
        measures: ["ImSaasUsers.count"],
        timeDimensions: [
          {
            dimension: "ImSaasUsers.createdAt",
            granularity: "day",
            dateRange: "Last 30 days",
          },
        ],
        filters: [
          {
            dimension: "ImSaasUsers.hasStripeId",
            operator: "equals",
            values: ["1"],
          },
          {
            dimension: "ImSaasUsers.email",
            operator: "notEquals",
            values: DashboardUtils.demoAndEmptyEmails,
          },
        ],
        dimensions: [],
        order: {
          "ImSaasUsers.createdAt": "asc",
        }
      },
      chartType: "line",
    },
  });

  dashboard_payments.push({
    id: 0,
    lg: 24,
    name: "Активные подписки",
    vizState: {
      query: {
        measures: [],
        timeDimensions: [{ dimension: "ImBillingSubscriptions.startsAt" }],
        dimensions: [
          "ImBillingSubscriptions.userId",
          "ImBillingSubscriptions.email",
          "ImBillingSubscriptions.amount",
          "ImBillingSubscriptions.endsAt",
        ],
        order: {},
        filters: [
          {
            dimension: "ImBillingSubscriptions.email",
            operator: "notEquals",
            values: DashboardUtils.demoAndEmptyEmails,
          },
        ],
      },
      chartType: "table",
      opts: {
        dataMap: (data) => {
          return data;
        },
        columns: (data) => {
          data[1].title = "Email";
          data[1].render = userCRMLinkRender("ImBillingSubscriptions.userId");

          data[2].title = "Amount";
          data[2].render = monyRender;

          return data.filter(
            (row) => !["ImBillingSubscriptions.userId"].includes(row.key)
          );
        },
        dataSource: (data) => {
          return data;
        },
      },
    },
  });

  dashboard_payments.push({
    id: 0,
    lg: 24,
    name: "Платежи за этот месяц",
    vizState: {
      query: {
        measures: [],
        timeDimensions: [
          { dimension: "ImBillingOrders.paid_at", dateRange: "This month" },
        ],
        dimensions: [
          "ImSaasUsers.id",
          "ImBillingOrders.amount",
          "ImBillingOrders.paid_at",
          "ImBillingOrders.plan_id",
          "ImBillingOrders.type",
          "ImBillingOrders.cluster_id",
          "ImSaasUsers.email",
        ],
        filters: [
          { dimension: "ImBillingOrders.transaction_data", operator: "set" },
          {
            dimension: "ImSaasUsers.email",
            operator: "notEquals",
            values: DashboardUtils.demoAndEmptyEmails,
          },
        ],
        order: { "ImBillingOrders.paid_at": "desc" },
      },
      chartType: "table",
      opts: {
        dataMap: (data) => {
          return data;
        },
        columns: (data) => {
          data[1].title = "Mony";
          data[1].render = monyRender;

          data[6].title = "Email";
          data[6].render = userCRMLinkRender("ImSaasUsers.id");

          return data.filter((row) => !["ImSaasUsers.id"].includes(row.key));
        },
        dataSource: (data) => {
          return data;
        },
      },
    },
  });

  dashboard_payments.push({
    id: 0,
    lg: 24,
    name: "Платежи за прошлый месяц",
    vizState: {
      query: {
        measures: [],
        timeDimensions: [
          { dimension: "ImBillingOrders.paid_at", dateRange: "Last month" },
        ],
        dimensions: [
          "ImSaasUsers.id",
          "ImBillingOrders.amount",
          "ImBillingOrders.paid_at",
          "ImBillingOrders.plan_id",
          "ImBillingOrders.type",
          "ImBillingOrders.cluster_id",
          "ImSaasUsers.email",
        ],
        filters: [
          { dimension: "ImBillingOrders.transaction_data", operator: "set" },
          {
            dimension: "ImSaasUsers.email",
            operator: "notEquals",
            values: DashboardUtils.demoAndEmptyEmails,
          },
        ],
        order: { "ImBillingOrders.paid_at": "desc" },
      },
      chartType: "table",
      opts: {
        dataMap: (data) => {
          return data;
        },
        columns: (data) => {
          data[1].title = "Mony";
          data[1].render = monyRender;

          data[6].title = "Email";
          data[6].render = userCRMLinkRender("ImSaasUsers.id");

          return data.filter((row) => !["ImSaasUsers.id"].includes(row.key));
        },
        dataSource: (data) => {
          return data;
        },
      },
    },
  });

  dashboard_payments.push({
    id: 0,
    lg: 24,
    name: "Привязка карт в страйп (Последние 100)",
    vizState: {
      query: {
        measures: ["ImSaasUsers.count", "ImbachatAnalyticsWidgets.count"],
        timeDimensions: [
          {
            dimension: "ImSaasUsers.lastLogin",
          },
        ],
        filters: [
          {
            dimension: "ImSaasUsers.hasStripeId",
            operator: "equals",
            values: ["1"],
          },
          {
            dimension: "ImSaasUsers.email",
            operator: "notEquals",
            values: DashboardUtils.demoAndEmptyEmails,
          },
        ],
        dimensions: [
          "ImSaasUsers.id",
          "ImSaasUsers.email",
          "ImSaasUsers.createdAt",
          "ImbasynergyImbachatDevelopers.host",
        ],
        order: {
          "ImSaasUsers.createdAt": "desc",
        },
        limit: 100
      },
      chartType: "table",
      opts: {
        dataMap: (data) => {
          return data;
        },
        columns: (data) => {
          data[1].title = "Email";
          data[1].render = userCRMLinkRender("ImSaasUsers.id");

          return data.filter((row) => !["ImSaasUsers.id"].includes(row.key));
        },
        dataSource: (data) => {
          return data;
        },
      },
    },
  });

  // Привязка карт в страйп (таблица с нужными полями)
  //{
  //"measures":["ImbachatAnalyticsWidgets.count"],
  //        "timeDimensions":[
  //    {"dimension":"ImbasynergyImbachatDevelopers.createdAt", "dateRange":"Last%2030%20days"}
  //],
  //        "dimensions":[
  //                "ImbasynergyImbachatDevelopers.host",
  //                "ImbasynergyImbachatDevelopers.cms",
  //                "ImReferrals.src",
  //                "ImReferrals.utmSource",
  //                "ImReferrals.utmCampaign",
  //                "ImSaasUsers.hasStripeId",
  //                "ImReferrals.utmContent",
  //                "ImSaasUsers.email"
  //            ],
  //        "filters":[
  //    {        "dimension":"ImSaasUsers.hasStripeId",
  //        "operator":"equals",
  //        "values":["1"]
  //    }
  //], "order":{
  //"ImbasynergyImbachatDevelopers.createdAt":"asc"
  //}
  //}
}

export { dashboard_payments };
