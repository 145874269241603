export const fetchFromApi = async (url, opt = {}) => {
  const headers = new Headers();
  headers.append("Content-type", "text/json");
  opt.mode = "cors";
  const res = await fetch(url, { ...opt, headers });

  const answer = await res.json();
  return answer;
};

const getUserOrders = async (id) => {
  return fetchFromApi(`https://imbachat.com/api/v2/orders?user_id=${id}`);
};

const getUserSubscriptions = async (id) => {
  return fetchFromApi(
    `https://imbachat.com/api/v2/subscriptions?user_id=${id}`
  );
};

const getUserWidgets = async (id) => {
  return fetchFromApi(`https://imbachat.com/api/v2/widgets?user_id=${id}`);
};

const getUserInfo = async (id) => {
  return fetchFromApi(`https://imbachat.com/api/v2/user/get?user_id=${id}`);
};

const getUserNotice = async (id) => {
  return fetchFromApi(`https://imbachat.com/api/v2/notice/get?user_id=${id}`);
};

export const loadUserAllData = async (userId) => {
  const orders = await getUserOrders(userId);
  const subscriptions = await getUserSubscriptions(userId);
  const widgets = await getUserWidgets(userId);
  const info = await getUserInfo(userId);
  const notice = await getUserNotice(userId);

  return {
    orders,
    subscriptions,
    widgets,
    info,
    notice,
  };
};
