
import {stripeIdRender, authTokenData, hostRender, utmTermRender, DashboardUtils} from '../DashboardUtils';
let dashboard_sites = [
    {
        id: 0,
        lg: 24,
        name: 'Активные интеграции за 7 дней',
        vizState: {
            query: {"measures": ["ImbachatAnalyticsWidgets.count"], "timeDimensions": [{"dimension": "ImbachatAnalyticsWidgets.createdAt", "dateRange": "Last 7 days", "granularity": "day"}], "dimensions": ["ImbasynergyImbachatDevelopers.host"], "order": {"ImbachatAnalyticsWidgets.count": "desc"},
                "filters": [
                    {"dimension": "ImbachatAnalyticsWidgets.event", "operator": "equals", "values": ["load"]},
                    {"dimension": "ImbasynergyImbachatDevelopers.host", "operator": "notEquals", "values": DashboardUtils.demoHosts},
                ]},
            chartType: 'line',
        },
    },
    {
        id: 0,
        lg: 24,
        name: 'Активные интеграции за 1 день',
        vizState: {
            query: {"measures": ["ImbachatAnalyticsWidgets.count"], "timeDimensions": [{"dimension": "ImbachatAnalyticsWidgets.createdAt", "dateRange": "Last 24 hours"}],
                "dimensions": [
                    "ImbachatAnalyticsWidgets.devId",
                    "ImbasynergyImbachatDevelopers.host",
                    "ImbasynergyImbachatDevelopers.cms",
                    "ImbasynergyImbachatDevelopers.createdAt",
                    "ImSaasUsers.hasStripeId"
                ], "order": {"ImbachatAnalyticsWidgets.count": "desc"}, "filters": [
                    {"dimension": "ImbachatAnalyticsWidgets.event", "operator": "equals", "values": ["load"]},
                    {"dimension": "ImbasynergyImbachatDevelopers.host", "operator": "notEquals", "values": DashboardUtils.demoHosts},
                ]},
            chartType: 'table',
            opts: {
                dataMap: (data) => {
                    return data
                },
                columns: (data) => {
                    data[0].title = 'Id'
                    data[1].title = 'Host'
                    data[1].render = hostRender
                    data[2].title = 'CMS'
                    data[2].render = (tag) => {
                        return (tag + "").replace("ImbaChat-", "")
                    }
                    data[3].title = 'Created At'
                    data[4].title = 'Card'
                    data[4].render = stripeIdRender
                    data[5].title = 'Loads'
                    return data
                },
                dataSource: (data) => {
                    return data
                },
            }
        },
    },
    {
        id: 0,
        lg: 24,
        name: 'Активные интеграции за 7 дней',
        vizState: {
            query: {"measures": ["ImbachatAnalyticsWidgets.count"], "timeDimensions": [{"dimension": "ImbachatAnalyticsWidgets.createdAt", "dateRange": "Last 7 days"}],
                "dimensions": [
                    "ImbachatAnalyticsWidgets.devId",
                    "ImbasynergyImbachatDevelopers.host",
                    "ImbasynergyImbachatDevelopers.cms",
                    "ImbasynergyImbachatDevelopers.createdAt",
                    "ImSaasUsers.hasStripeId"
                ], "order": {"ImbachatAnalyticsWidgets.count": "desc"}, "filters": [
                    {"dimension": "ImbachatAnalyticsWidgets.event", "operator": "equals", "values": ["load"]},
                    {"dimension": "ImbasynergyImbachatDevelopers.host", "operator": "notEquals", "values": DashboardUtils.demoHosts},
                ]},
            chartType: 'table',
            opts: {
                dataMap: (data) => {
                    return data
                },
                columns: (data) => {
                    data[0].title = 'Id'
                    data[1].title = 'Host'
                    data[1].render = hostRender
                    data[2].title = 'CMS'
                    data[2].render = (tag) => {
                        return (tag + "").replace("ImbaChat-", "")
                    }
                    data[3].title = 'Created At'
                    data[4].title = 'Card'
                    data[4].render = stripeIdRender
                    data[5].title = 'Loads'
                    return data
                },
                dataSource: (data) => {
                    return data
                },
            }
        },
    },
]

  export {dashboard_sites};
