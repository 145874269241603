import React from "react";
import { Link } from "react-router-dom";
import { Tag, Popover } from "antd";

export function usdRender(val, record, index) {
  let color = "geekblue";
  let text = `$ ${val}`;

  return (
    <Tag color={color} key={val}>
      {text}
    </Tag>
  );
}

export function monyRender(val, record, index) {
  let color = "geekblue";
  let text = `$ ${val / 100}`;
  if (val < 0) {
    color = "red";
  }
  return (
    <Tag color={color} key={val}>
      {text}
    </Tag>
  );
}

export function isActive(val) {
  let color = "red";
  let text = `No - ${Math.floor(val / 24)}`;
  if (val < 48) {
    color = "green";
    text = "Yes";
  }
  return (
    <Tag
      color={color}
      key={val}
      title={`Последнее обновление ${Math.floor(val / 24)} дней назад`}
    >
      {text}
    </Tag>
  );
}

export function isOld(val) {
  let color = "geekblue";
  if (val > 24 * 7) {
    color = "green";
  }
  return (
    <Tag
      color={color}
      key={val}
      title={`Был активен ${Math.floor(val / 24)} дней`}
    >
      {Math.floor(val / 24)}
    </Tag>
  );
}

export function jsonPluginsRender(val) {
  let color = "gray";
  if (val) {
    color = "geekblue";
  }
  let arr = [];
  try {
    arr = JSON.parse(val);
  } catch (e) {}

  if (!arr.map) {
    arr = Object.keys(arr).map((k) => arr[k]);
  }
  if (!arr || !arr.map) {
    arr = [arr];
  }
  return (
    <Popover
      content={arr.map((v) => (
        <p>{v}</p>
      ))}
      title="Data"
    >
      <Tag color={color} key={val}>
        {arr.length}
      </Tag>
    </Popover>
  );
}

export function timeRender(val) {
  if (!val) {
    return "";
  }

  const d = new Date(val + "Z");
  if (!d) {
    return "";
  }

  return `${d.toLocaleDateString()} ${d.toLocaleTimeString()}`;
}

export function timeRenderAbdHowLong(val) {
  if (!val) {
    return "";
  }

  const d = new Date(val + "Z");
  const valT = (new Date().getTime() - d.getTime()) / 1000 / 3600 / 24;
  if (!d) {
    return "";
  }

  let color = "geekblue";
  if (valT > 32) {
    color = "red";
  }

  return (
    <div key={val}>
      <Tag>
        {d.toLocaleDateString()} {d.toLocaleTimeString()}
      </Tag>
      <Tag color={color} title={`Был ${Math.floor(valT)} дней`}>
        {Math.floor(valT)}
      </Tag>
    </div>
  );
}

export function stripeIdRender(tag) {
  let color = "red";
  let text = "No";
  if (tag === 1) {
    color = "green";
    text = "Yes";
  }
  return (
    <Tag color={color} key={tag}>
      {text}
    </Tag>
  );
}

// https://imbachat.com/backend/rainlab/user/users/preview/1397
export function emailRender(val) {
  if (!val) {
    return "";
  }

  if (!val.replace) {
    return val;
  }
  return <div title={val}>{val.replace(/^https?:\/\//, "").slice(0, 16)}</div>;
}

export function hostRender(val) {
  if (!val) {
    return "";
  }

  if (!val.replace) {
    return val;
  }

  let text = val.replace(/^https?:\/\//, "").slice(0, 20);

  return (
    <a
      href={`http://${val.replace(/^https?:\/\//, "")}`}
      target="_blank"
      title={val}
    >
      {text}
    </a>
  );
}

export function utmTermRender(val) {
  if (!val || val === "null") return "";

  let color = "purple";
  if (/^https?:/.test(val)) {
    color = "blue";
    let text = val.replace(/^https?:\/\//, "").slice(0, 20);

    return (
      <Tag color={color} key={val} title={val}>
        <a href={val}>{text}</a>
      </Tag>
    );
  }

  return (
    <Tag color={color} key={val} title={val}>
      {val.slice(0, 24)}
    </Tag>
  );
}

let parseJwt = (token) => {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function(c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
};

export function authTokenData() {
  if (
    !window.localStorage["cubekey"] ||
    window.localStorage["cubekey"].length < 30
  ) {
    window.localStorage["cubekey"] = prompt("API KEY");
  }
  return parseJwt(window.localStorage["cubekey"]);
}

export function userCRMLinkRender(userIdKey) {
  return (val, record, index) => {
    let color = "purple";
    let text = val;
    return (
      <Tag color={color} key={val}>
        <Link to={`/crm/user-card/${record[userIdKey]}`}>{text}</Link>
      </Tag>
    );
  };
}

const DashboardUtils = {};

DashboardUtils.demoHosts = [
  "imbachat.com",
  "api.imbachat.com",
  "buddypress.imbachat.com",
  "market.imbachat.com",
  "opencart.imbachat.com",
  "october.imbachat.com",
  "wordpress.imbachat.com",
  "sweet.imbachat.com",
];

DashboardUtils.demoEmails = [
  "viktoria@imbasynergy.com",
  "levhav@yandex.ru",
  "osmonab@gmail.com",
  "danil-kravtsiv@mail.ru",
  "levhav@ya2test.ru",
  "levhav@yandex.com",
  "callywolf100@gmail.com",
  "sprayDev@yandex.ru",
  "victor@imbachat.com",
  "danilkravciv@gmail.com",
  "torasa.i.2611@gmail.com",
  "torasa.i2611@gmail.com",
  "newpage@mail.ru",
  "samsung@mail.ru",
  "s.upport@imbachat.com",
  "cypresstest@gmail.com",
  "synergyimba@gmail.com",
  "Yuliiaryvkina@ukr.net",
  "afsgrgrsgsg@gmail.com",
  "capella777@ukr.net",
  "berevels@gmail.com",
  "strepsis1187@gmail.com",
  "sdfdthhcfvcgh@gmail.com",
  "levhav33@yandex.com",
  "fsdf4fgdfhdrh@sdfds.com",
  "dfgdfgdhdh@sdfdsf.com",
  "sgwegewgwg@dfgdf.com",
  "fedrhgfhreher@gmail.com",
  "t.o.rasai2611@gmail.com",
  "sdgr4egdsgdsgf@gmail.com",
  "f3wfsdgfsrghdghd@gmail.com",
  "sdgr4egdsgdsgf@gmail.com",
  "fedrhgfhreher@gmail.com",
  "sdfyhfgjutgdsgs@gmail.com",
  "lakmiseva@gmail.com",
  "Yuliiaryvkina@ukr.net",
  "qwerty@mail.ru",
  "goldvilk@gmail.com",
  "lukirebatf@gmail.com",
  "mukeveres@mail.ua",
  "jukliverstah@gmail.com",
  "gferemed@mail.ua",
  "sdfsgfdgdfdgfg1@gsfewfsf.com",
  "rdn1@ukr.net",
  "lorsefraguk@gmail.com",
  "zooshop_test@mail.ru",
  "gereteper@yahoo.com",
  "gereteper1@yahoo.com",
  "gereteper2@yahoo.com",
  "gereteper3@yahoo.com",
];

DashboardUtils.demoAndEmptyEmails = [...DashboardUtils.demoEmails, null];

export { DashboardUtils };
