 
let dashboard_langs = [
    {
        id: 0,
        lg: 24,
        name: 'Языки посетителей (За 30 дней)',
        vizState: {
            query: {
                "measures": ["ImbachatAnalyticsWidgets.count"], 
                "timeDimensions": [{"dimension": "ImbachatAnalyticsWidgets.createdAt", "dateRange": "Last 30 days", "granularity": "day"}],
                "filters": [
                    {"dimension": "ImbachatAnalyticsWidgets.event", "operator": "equals", "values": ["load"]},
                    // {"dimension":"ImbachatAnalyticsWidgets.count","operator":"gt","values":["500"]} 
                ],
                "dimensions": ["ImbachatAnalyticsWidgets.lang"],
                "order": {"ImbachatAnalyticsWidgets.count": "desc"},
                "limit":"10",
            },
            chartType: 'line',
        },
    },

    {
        id: 0,
        lg: 12,
        name: 'Языки посетителей (За 30 дней)',
        vizState: {
            query: {"measures": ["ImbachatAnalyticsWidgets.count"], "timeDimensions": [{"dimension": "ImbachatAnalyticsWidgets.createdAt", "dateRange": "Last 30 days"}], "filters": [{"dimension": "ImbachatAnalyticsWidgets.event", "operator": "equals", "values": ["load"]}], "dimensions": ["ImbachatAnalyticsWidgets.lang"], "order": {"ImbachatAnalyticsWidgets.count": "desc"}},
            chartType: 'pie',
        },
    },
    {
        id: 0,
        lg: 12,
        name: 'Языки посетителей (За 30 дней)',
        vizState: {
            query: {"measures": ["ImbachatAnalyticsWidgets.count"], "timeDimensions": [{"dimension": "ImbachatAnalyticsWidgets.createdAt", "dateRange": "Last 30 days"}], "filters": [{"dimension": "ImbachatAnalyticsWidgets.event", "operator": "equals", "values": ["load"]}], "dimensions": ["ImbachatAnalyticsWidgets.lang"], "order": {}},
            chartType: 'table',
        },
    },
]


  export {dashboard_langs};