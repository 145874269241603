import './body.css';
import 'antd/dist/antd.css';
import React from 'react';
import '@ant-design/compatible';
import { Layout } from 'antd';
import cubejs from '@cubejs-client/core';
import { CubeProvider } from '@cubejs-client/react';
import Header from './components/Header';
import WebSocketTransport from '@cubejs-client/ws-transport';
const API_URL = 'https://crm.imbachat.com/api';
 
if(!window.localStorage['cubekey'] || window.localStorage['cubekey'].length < 30){
 window.localStorage['cubekey'] = prompt('API KEY');
}

if(!window.localStorage['crmkey'] || window.localStorage['crmkey'].length < 10){
 window.localStorage['crmkey'] = prompt('crmkey KEY');
}

const CUBEJS_TOKEN = window.localStorage['cubekey']
const cubejsApi = cubejs({
  transport: new WebSocketTransport({
    authorization: CUBEJS_TOKEN,
    apiUrl: API_URL.replace('http', 'ws'),
  }),
});

const AppLayout = ({ children }) => (
  <Layout
    style={{
      height: '100%',
    }}
  >
    <Header />
    <Layout.Content>{children}</Layout.Content>
  </Layout>
);

const App = ({ children }) => (
  <CubeProvider cubejsApi={cubejsApi}>
    <AppLayout>{children}</AppLayout>
  </CubeProvider>
);

export default App;
