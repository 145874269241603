import React from "react";
import { Col, Tag } from "antd";
import ChartRenderer from "../components/ChartRenderer";
import Dashboard from "../components/Dashboard";
import DashboardItem from "../components/DashboardItem";

import { useParams } from "react-router-dom";

import { dashboard_ADS } from "./dashboardPageADS";
import { dashboard_payments } from "./dashboardPagePayments";
import { dashboard_buyers } from "./dashboardPageBuyers";
import { dashboard_main } from "./dashboardPageMain";
import { dashboard_langs } from "./dashboardPageLangs";
import { dashboard_api } from "./dashboardPageAPI";
import { dashboard_sites } from "./dashboardPageSites";
import { dashboard_widgets } from "./dashboardPageWidgets";
import { dashboard_wp } from "./dashboardPageWP";
import { dashboard_wp_rate } from "./dashboardPageWPRate";
import { dashboard_wp_deactivate } from "./dashboardPageWPDeactivate";
import { dashboard_balance } from "./dashboardPageBalance";
import { dashboard_kpi } from "./dashboardPageKPI";

const dashboard_links_RfSystem = [
  {
    lg: 24,
    id: 0,
    name: "Регистрации по ссылкам (За 30 дней)",
    vizState: {
      query: {
        measures: ["ImReferrals.count"],
        timeDimensions: [
          {
            dimension: "ImReferrals.createdAt",
            granularity: "day",
            dateRange: "Last 30 days",
          },
        ],
        dimensions: ["ImReferrals.httpReferer"],
        order: { "ImReferrals.count": "desc" },
      },
      chartType: "line",
    },
  },
  {
    lg: 24,
    id: 0,
    name: "Регистрации по ссылкам (всё время, топ 200)",
    vizState: {
      query: {
        measures: ["ImReferrals.count"],
        timeDimensions: [{ dimension: "ImReferrals.createdAt" }],
        dimensions: ["ImReferrals.httpReferer"],
        order: { "ImReferrals.count": "desc" },
        limit: 200,
      },
      chartType: "table",
    },
  },
];

// https://imbachat.com/backend/rainlab/user/users/preview/794#primarytab-rainlabuserlanguseraccount

// dashboard_api

let dArea = {
  main: dashboard_main,
  api: dashboard_api,
  langs: dashboard_langs,
  sites: dashboard_sites,
  widgets: dashboard_widgets,
  ads: dashboard_ADS,
  payments: dashboard_payments,
  buyers: dashboard_buyers,
  links: dashboard_links_RfSystem,
  wp: dashboard_wp,
  "wp-rate": dashboard_wp_rate,
  "wp-deactivate": dashboard_wp_deactivate,
  "balance": dashboard_balance,
  "kpi": dashboard_kpi,
};

const DashboardPage = () => {
  const dashboardItem = (item) => (
    <Col
      span={item.span || 24}
      lg={item.lg || 12}
      key={item.id}
      style={{
        marginBottom: "24px",
      }}
    >
      <DashboardItem title={item.name}>
        <ChartRenderer vizState={item.vizState} />
      </DashboardItem>
    </Col>
  );

  const Empty = () => (
    <div
      style={{
        textAlign: "center",
        padding: 12,
      }}
    >
      <h2>
        There are no charts on this dashboard. Use Playground Build to add one.
      </h2>
    </div>
  );

  let { id } = useParams();
  const DashboardItems = dArea[id || "main"];
  return DashboardItems.length ? (
    <Dashboard dashboardItems={DashboardItems}>
      {DashboardItems.map(dashboardItem)}
    </Dashboard>
  ) : (
    <Empty />
  );
};

export default DashboardPage;
