
import {stripeIdRender, authTokenData, hostRender, utmTermRender, DashboardUtils, timeRender} from '../DashboardUtils';
let dashboard_widgets = [
    {
        id: 0,
        lg: 8,
        name: 'Создания виджетов по месяцам',
        vizState: {
            query: {"measures": ["ImbasynergyImbachatDevelopers.count"], "timeDimensions": [{"dimension": "ImbasynergyImbachatDevelopers.createdAt", "granularity": "month"}], "order": {"ImbasynergyImbachatDevelopers.createdAt": "asc"}},
            chartType: 'line',
        },
    },
    {
        id: 0,
        lg: 8,
        name: 'Создания виджетов по неделям',
        vizState: {
            query: {"measures": ["ImbasynergyImbachatDevelopers.count"], "timeDimensions": [{"dimension": "ImbasynergyImbachatDevelopers.createdAt", "granularity": "week"}], "order": {"ImbasynergyImbachatDevelopers.createdAt": "asc"}},
            chartType: 'line',
        },
    },
    {
        id: 0,
        lg: 8,
        name: 'Создания виджетов по дням за последние 90 дней',
        vizState: {
            query: {"measures": ["ImbasynergyImbachatDevelopers.count"], "timeDimensions": [{"dimension": "ImbasynergyImbachatDevelopers.createdAt", "granularity": "day", "dateRange": "Last 90 days"}], "order": {"ImbasynergyImbachatDevelopers.createdAt": "asc"}},
            chartType: 'line',
        },
    },
    {
        id: 0,
        lg: 24,
        name: 'Виджеты созданные в последние 7 дней (сортировка по дате создания)',
        vizState: {
            query: {"measures": ["ImbachatAnalyticsWidgets.count"], "timeDimensions": [{"dimension": "ImbasynergyImbachatDevelopers.createdAt", "dateRange": "Last 7 days"}], "dimensions": [
                    "ImSaasUsers.email",  
                    "ImbasynergyImbachatDevelopers.host", "ImbasynergyImbachatDevelopers.cms", "ImReferrals.src", "ImReferrals.utmSource", "ImReferrals.utmCampaign", "ImSaasUsers.hasStripeId", "ImReferrals.utmContent",
 "ImbasynergyImbachatDevelopers.userId", "ImbasynergyImbachatDevelopers.createdAt"],
                "order": {"ImbasynergyImbachatDevelopers.createdAt": "desc"}},
            chartType: 'table',
            opts: {
                dataMap: (data) => {
                    return data
                },
                columns: (data) => {
                    data[0].title = 'Email'
                    data[1].title = 'Host'
                    data[1].render = hostRender
                    data[2].title = 'CMS'
                    data[2].render = (tag) => {
                        return (tag + "").replace("ImbaChat-", "")
                    }
                    data[3].title = 'UTM Term'
                    data[3].render = utmTermRender
                    data[4].title = 'UTM Source'
                    data[5].title = 'UTM Campaign'
                    data[6].title = 'Card'
                    data[6].render = stripeIdRender
                    data[7].title = 'UTM Content'
                    data[8].title = 'userId'
                    data[9].title = 'createdAt'
                    data[9].render = timeRender
                    data[10].title = 'Loads'
                    return data
                },
                dataSource: (data) => {
                    return data
                },
            }
        }
    },
]
  export {dashboard_widgets};
