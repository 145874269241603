import React from 'react';
import { Col, Tag, Popover } from 'antd';
import {stripeIdRender, authTokenData, hostRender, utmTermRender, DashboardUtils, timeRender, emailRender} from '../DashboardUtils';
 
let dashboard_wp_deactivate = [
     
    {
        id: 0,
        lg: 24,
        name: 'Деактивации WP',
        vizState: {
            query: {
  "measures": [
    "ImWpDeactivate.count"
  ],
  "timeDimensions": [
    {
      "dimension": "ImWpDeactivate.updatedAt",
      "granularity": "day"
    }
  ],
  "dimensions": [
    "ImWpDeactivate.reason"
  ],
  "order": {},
  "filters": []
},
            chartType: 'bar',
            opts: {
                dataMap: (data) => {
                    return data
                },
                columns: (data) => {  
                    return data
                },
                dataSource: (data) => {
                    return data
                },
            }
        }
    }, 
    {
        id: 0,
        lg: 24,
        name: 'Деактивации WP',
        vizState: {
            query: {
                    "measures": [],
                    "timeDimensions": [
                      {
                        "dimension": "ImWpDeactivate.createdAt"
                      }
                    ],
                    "dimensions": [
                      "ImWpDeactivate.devId",
                      "ImWpDeactivate.reason",
                      "ImWpDeactivate.createdAt"
                    ],
                    "order": {},
                    "filters": []
                  },
            chartType: 'table',
            opts: {
                dataMap: (data) => {
                    return data
                },
                columns: (data) => {  
                    return data
                },
                dataSource: (data) => {
                    return data
                },
            }
        }
    },
   
]
  export {dashboard_wp_deactivate};
