
const dashboard_api = [

    {
        id: 0,
        name: 'Среднее время ответов на апи запросы',
        vizState: {
            query: {
                "measures": [
                    "ImbachatAnalyticsWidgets.apiTimeCallMax",
                    "ImbachatAnalyticsWidgets.apiTimeCallAvg"
                ],
                "timeDimensions": [
                    {
                        "dimension": "ImbachatAnalyticsWidgets.createdAt",
                        "granularity": "day",
                        "dateRange": "Last 30 days"
                    }
                ],
                "filters": [
                    {"member": "ImbachatAnalyticsWidgets.event", "operator": "equals", "values": ["apiCall"]}
                ], "order": {}},
            chartType: 'line',
        },
    },
    {
        id: 0,
        name: 'Количество АПИ запросов к сайту от чата',
        vizState: {
            query: {
                "measures": [
                    "ImbachatAnalyticsWidgets.count"
                ],
                "timeDimensions": [
                    {
                        "dimension": "ImbachatAnalyticsWidgets.createdAt",
                        "granularity": "day",
                        "dateRange": "Last 30 days"
                    }
                ],
                "filters": [
                    {"member": "ImbachatAnalyticsWidgets.event", "operator": "equals", "values": ["apiCall"]}
                ], "order": {}
            },
            chartType: 'line',
        },
    },
];


  export {dashboard_api};