import {
  timeRenderAbdHowLong,
  DashboardUtils,
  userCRMLinkRender,
  monyRender,
} from "../DashboardUtils";
const dashboard_buyers = [];

dashboard_buyers.push({
  id: 0,
  lg: 24,
  name: "Лояльность покупателей за месяц",
  vizState: {
    query: {
      measures: [],
      timeDimensions: [
        {
          dimension: "ImBuyersLoyalty.last_paid_at",
          dateRange: "Last 30 days",
        },
      ],
      dimensions: [
        "ImSaasUsers.id",
        "ImSaasUsers.email",
        "ImBuyersLoyalty.last_paid_at",
        "ImBuyersLoyalty.sumOrders",
        "ImBuyersLoyalty.countOrders",
      ],
      order: {
        "ImBuyersLoyalty.last_paid_at": "desc",
      },
      filters: [
        {
          dimension: "ImSaasUsers.email",
          operator: "notEquals",
          values: DashboardUtils.demoAndEmptyEmails,
        },
        {
          dimension: "ImBuyersLoyalty.last_paid_at",
          operator: "notEquals",
          values: [""],
        },
      ],
    },
    chartType: "table",
    opts: {
      dataMap: (data) => {
        return data;
      },
      columns: (data) => {
        data[1].title = "Email";
        data[1].render = userCRMLinkRender("ImSaasUsers.id");

        data[2].title = "Последний платёж";
        data[2].render = timeRenderAbdHowLong;

        data[3].title = "Доход";
        data[3].render = monyRender;

        return data.filter((row) => !["ImSaasUsers.id"].includes(row.key));
      },
      dataSource: (data) => {
        return data;
      },
    },
  },
});

dashboard_buyers.push({
  id: 0,
  lg: 24,
  name: "Лояльность покупателей за всё время",
  vizState: {
    query: {
      measures: [],
      timeDimensions: [
        {
          dimension: "ImBuyersLoyalty.last_paid_at",
          // dateRange: "Last 30 days",
        },
      ],
      dimensions: [
        "ImSaasUsers.id",
        "ImSaasUsers.email",
        "ImBuyersLoyalty.last_paid_at",
        "ImBuyersLoyalty.sumOrders",
        "ImBuyersLoyalty.countOrders",
      ],
      order: {
        "ImBuyersLoyalty.last_paid_at": "desc",
      },
      filters: [
        {
          dimension: "ImSaasUsers.email",
          operator: "notEquals",
          values: DashboardUtils.demoAndEmptyEmails,
        },
        {
          dimension: "ImBuyersLoyalty.last_paid_at",
          operator: "set", 
        },
      ],
    },
    chartType: "table",
    opts: {
      dataMap: (data) => {
        return data;
      },
      columns: (data) => {
        data[1].title = "Email";
        data[1].render = userCRMLinkRender("ImSaasUsers.id");

        data[2].title = "Последний платёж";
        data[2].render = timeRenderAbdHowLong;

        data[3].title = "Доход";
        data[3].render = monyRender;

        return data.filter((row) => !["ImSaasUsers.id"].includes(row.key));
      },
      dataSource: (data) => {
        return data;
      },
    },
  },
});

export { dashboard_buyers };
