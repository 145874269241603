import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter as Router, Route } from 'react-router-dom';
import DashboardPage from './pages/DashboardPage';
import CRMUserCard from './pages/CRMUserCard';
import App from './App';


ReactDOM.render(
  <Router>
    <App>
      <Route key="index" exact path="/" component={DashboardPage} />
      <Route key="other" exact path="/crm/user-card/:userId" component={CRMUserCard} />
      <Route key="other" exact path="/:id" component={DashboardPage} />
    </App>
  </Router>, // eslint-disable-next-line no-undef
  document.getElementById('root')
);
