const dashboard_main = [
  {
    lg: 8,
    id: 0,
    name: "Загрузки виджета пользователями (по месяцам)",
    vizState: {
      query: {
        measures: ["ImbachatAnalyticsWidgets.count"],
        timeDimensions: [
          {
            dimension: "ImbachatAnalyticsWidgets.createdAt",
            granularity: "month",
            dateRange: "Last 365 days",
          },
        ],
        filters: [
          {
            member: "ImbachatAnalyticsWidgets.event",
            operator: "equals",
            values: ["load"],
          },
        ],
        order: {},
      },
      chartType: "line",
    },
  },
  {
    lg: 8,
    id: 0,
    name: "Загрузки виджета пользователями (по неделям)",
    vizState: {
      query: {
        measures: ["ImbachatAnalyticsWidgets.count"],
        timeDimensions: [
          {
            dimension: "ImbachatAnalyticsWidgets.createdAt",
            granularity: "week",
            dateRange: "Last 180 days",
          },
        ],
        filters: [
          {
            member: "ImbachatAnalyticsWidgets.event",
            operator: "equals",
            values: ["load"],
          },
        ],
        order: {},
      },
      chartType: "line",
    },
  },
  {
    lg: 8,
    id: 0,
    name: "Загрузки виджета пользователями (по дням)",
    vizState: {
      query: {
        measures: ["ImbachatAnalyticsWidgets.count"],
        timeDimensions: [
          {
            dimension: "ImbachatAnalyticsWidgets.createdAt",
            granularity: "day",
            dateRange: "Last 30 days",
          },
        ],
        filters: [
          {
            member: "ImbachatAnalyticsWidgets.event",
            operator: "equals",
            values: ["load"],
          },
        ],
        order: {},
      },
      chartType: "line",
    },
  },

  {
    lg: 8,
    id: 0,
    name: "Новые пользователи в чате по месяцам",
    vizState: {
      query: {
        measures: ["ImbachatUsers.count"],
        timeDimensions: [
          {
            dimension: "ImbachatUsers.createdAt",
            granularity: "month",
            dateRange: "Last 365 days",
          },
        ],
        filters: [],
        order: {},
      },
      chartType: "line",
    },
  },

  {
    lg: 8,
    id: 0,
    name: "Новые пользователи в чате по неделям",
    vizState: {
      query: {
        measures: ["ImbachatUsers.count"],
        timeDimensions: [
          {
            dimension: "ImbachatUsers.createdAt",
            granularity: "week",
            dateRange: "Last 180 days",
          },
        ],
        filters: [],
        order: {},
      },
      chartType: "line",
    },
  },
  {
    lg: 8,
    id: 0,
    name: "Новые пользователи в чате по дням за последние 30 дней",
    vizState: {
      query: {
        measures: ["ImbachatUsers.count"],
        timeDimensions: [
          {
            dimension: "ImbachatUsers.createdAt",
            granularity: "day",
            dateRange: "Last 30 days",
          },
        ],
        filters: [],
        order: {},
      },
      chartType: "line",
    },
  },

  {
    lg: 8,
    id: 0,
    name: "Новые диалоги в чате по месяцам",
    vizState: {
      query: {
        measures: ["ImbachatRoominfo.count"],
        timeDimensions: [
          {
            dimension: "ImbachatRoominfo.createdAt",
            granularity: "month",
            dateRange: "Last 365 days",
          },
        ],
        filters: [],
        order: {},
      },
      chartType: "line",
    },
  },

  {
    lg: 8,
    id: 0,
    name: "Новые диалоги в чате по неделям",
    vizState: {
      query: {
        measures: ["ImbachatRoominfo.count"],
        timeDimensions: [
          {
            dimension: "ImbachatRoominfo.createdAt",
            granularity: "week",
            dateRange: "Last 180 days",
          },
        ],
        filters: [],
        order: {},
      },
      chartType: "line",
    },
  },

  {
    lg: 8,
    id: 0,
    name: "Новые диалоги в чате по дням за последние 30 дней",
    vizState: {
      query: {
        measures: ["ImbachatRoominfo.count"],
        timeDimensions: [
          {
            dimension: "ImbachatRoominfo.createdAt",
            granularity: "day",
            dateRange: "Last 30 days",
          },
        ],
        filters: [],
        order: {},
      },
      chartType: "line",
    },
  },

  {
    lg: 8,
    id: 0,
    name: "Сообщения пользователей (месяцы)",
    vizState: {
      query: {
        measures: ["ImbachatMessages.count"],
        timeDimensions: [
          {
            dimension: "ImbachatMessages.createdAt",
            granularity: "month",
            dateRange: "Last 365 days",
          },
        ],
        filters: [],
        order: {},
      },
      chartType: "line",
    },
  },

  {
    lg: 8,
    id: 0,
    name: "Сообщения пользователей (недели)",
    vizState: {
      query: {
        measures: ["ImbachatMessages.count"],
        timeDimensions: [
          {
            dimension: "ImbachatMessages.createdAt",
            granularity: "week",
            dateRange: "Last 180 days",
          },
        ],
        filters: [],
        order: {},
      },
      chartType: "line",
    },
  },

  {
    lg: 8,
    id: 0,
    name: "Сообщения пользователей (дни)",
    vizState: {
      query: {
        measures: ["ImbachatMessages.count"],
        timeDimensions: [
          {
            dimension: "ImbachatMessages.createdAt",
            granularity: "day",
            dateRange: "Last 30 days",
          },
        ],
        filters: [],
        order: {},
      },
      chartType: "line",
    },
  },
];

export { dashboard_main };
