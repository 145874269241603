import React from "react";
import { Col, Tag, Popover } from "antd";
import {
  isActive,
  isOld,
  jsonPluginsRender,
  stripeIdRender,
  authTokenData,
  hostRender,
  utmTermRender,
  DashboardUtils,
  timeRender,
  emailRender,
  userCRMLinkRender,
} from "../DashboardUtils";

// {"measures":["ImWpData.count"],"timeDimensions":[{"dimension":"ImWpData.updatedAt","granularity":"day","dateRange":"Last 30 days"}],"order":{}}
// {"measures":["ImWpData.count"],"timeDimensions":[{"dimension":"ImWpData.createdAt","granularity":"day","dateRange":"Last 30 days"}],"order":{}}

let dashboard_wp = [
  {
    id: 0,
    lg: 24,
    name: "Виджеты WP (Последние 200)",
    vizState: {
      query: {
        timezone: "Asia/Vladivostok",
        measures: [],
        timeDimensions: [{ dimension: "ImWpData.createdAt" }],
        dimensions: [
          "ImWpData.host",
          "ImWpData.devId",
          "ImWpData.liveTime",
          "ImWpData.lastUpdate",
          "ImWpData.createdAt",
          "ImWpData.lang",
          "ImWpData.pluginVersion",
          "ImWpData.template",
          "ImWpData.plugins",
          "ImSaasUsers.email",
          "ImWpData.userId",
          "ImSaasUsers.hasStripeId",
        ],
        order: { "ImWpData.lastUpdate": "asc", "ImWpData.createdAt": "desc" },
        limit: 200,
      },
      chartType: "table",
      opts: {
        dataMap: (data) => {
          return data;
        },
        columns: (data) => {
          data[0].title = "Host";
          data[0].render = hostRender;

          data[1].title = "DevId";
          data[2].title = "liveTime";
          data[2].render = isOld;
          data[3].title = "Active"; // lastUpdate
          data[3].render = isActive;

          data[4].title = "createdAt";
          data[4].render = timeRender;
          //                    data[4].render = hostRender

          data[5].title = "Lang";
          data[6].title = "Version";
          data[7].title = "template";
          data[8].title = "plugins";
          data[8].render = jsonPluginsRender;

          data[9].title = "Email";
          data[9].render = userCRMLinkRender("ImWpData.userId");

          data[11].title = "Card";
          data[11].render = stripeIdRender;

          return data.filter((row) => !["ImWpData.userId"].includes(row.key));
        },
        dataSource: (data) => {
          return data;
        },
      },
    },
  },
];
export { dashboard_wp };
