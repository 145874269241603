import React from 'react';
import PropTypes from 'prop-types';
import { useCubeQuery } from '@cubejs-client/react';
import { Spin, Row, Col, Statistic, Table } from 'antd';
import { Line, Bar, Pie } from 'react-chartjs-2';

const removeZero = (str) => {
    return str.replace(/\.000$/g, "").replace(/:00$/g, "").replace(/:00$/g, "").replace(/T00$/g, "");
}

// https://gist.github.com/mucar/3898821
const COLORS_SERIES = ['#FF6492', '#141446', '#7A77FF', '#FF6633', '#FFB399', '#FF33FF', '#FFFF99', '#00B3E6',
    '#E6B333', '#3366E6', '#999966', '#99FF99', '#B34D4D',
    '#80B300', '#809900', '#E6B3B3', '#6680B3', '#66991A',
    '#FF99E6', '#CCFF1A', '#FF1A66', '#E6331A', '#33FFCC',
    '#66994D', '#B366CC', '#4D8000', '#B33300', '#CC80CC',
    '#66664D', '#991AFF', '#E666FF', '#4DB3FF', '#1AB399',
    '#E666B3', '#33991A', '#CC9999', '#B3B31A', '#00E680',
    '#4D8066', '#809980', '#E6FF80', '#1AFF33', '#999933',
    '#FF3380', '#CCCC00', '#66E64D', '#4D80CC', '#9900B3',
    '#E64D66', '#4DB380', '#FF4D4D', '#99E6E6', '#6666FF'];

const TypeToChartComponent = {
    line: ({ resultSet }) => {
        const data = {
            labels: resultSet.categories().map((c) => c.category).map(removeZero),
            datasets: resultSet.series().map((s, index) => ({
                    label: s.title,
                    data: s.series.map((r) => r.value),
                    borderColor: COLORS_SERIES[index],
                    fill: false,
                })),
        };
        const options = {};
        return <Line data={data} options={options} />;
    },
    bar: ({ resultSet }) => {
        const data = {
            labels: resultSet.categories().map((c) => c.category),
            datasets: resultSet.series().map((s, index) => ({
                    label: s.title,
                    data: s.series.map((r) => r.value),
                    backgroundColor: COLORS_SERIES[index],
                    fill: false,
                })),
        };
        const options = {
            scales: {
                xAxes: [
                    {
                        stacked: true,
                    },
                ],
            },
        };
        return <Bar data={data} options={options} />;
    },
    area: ({ resultSet }) => {
        const data = {
            labels: resultSet.categories().map((c) => c.category),
            datasets: resultSet.series().map((s, index) => ({
                    label: s.title,
                    data: s.series.map((r) => r.value),
                    backgroundColor: COLORS_SERIES[index],
                })),
        };
        const options = {
            scales: {
                yAxes: [
                    {
                        stacked: true,
                    },
                ],
            },
        };
        return <Line data={data} options={options} />;
    },
    pie: ({ resultSet }) => {
        const data = {
            labels: resultSet.categories().map((c) => c.category),
            datasets: resultSet.series().map((s) => ({
                    label: s.title,
                    data: s.series.map((r) => r.value),
                    backgroundColor: COLORS_SERIES,
                    hoverBackgroundColor: COLORS_SERIES,
                })),
        };
        const options = {};
        return <Pie data={data} options={options} />;
    },
    number: ({ resultSet }) => (
                <Row
                    type="flex"
                    justify="center"
                    align="middle"
                    style={{
                                    height: '100%',
                                }}
                    >
                    <Col>
                    {resultSet.seriesNames().map((s) => (
                                        <Statistic value={resultSet.totalRow()[s.key]} />
                                    ))}
                    </Col>
                </Row>
                ),
    table: ({ resultSet, pivotConfig, opts }) => {

        if (opts && opts.dataMap) {
            resultSet = opts.dataMap(resultSet)
        }
        let columns = resultSet.tableColumns(pivotConfig)
        let dataSource = resultSet.tablePivot(pivotConfig)

        if (opts && opts.columns) {
            columns = opts.columns(columns)
        }
        if (opts && opts.dataSource) {
            dataSource = opts.dataSource(dataSource)
        }

        return <Table
            pagination={false}
            columns={columns}
            dataSource={dataSource}
            />
    },
};
const TypeToMemoChartComponent = Object.keys(TypeToChartComponent)
        .map((key) => ({
                [key]: React.memo(TypeToChartComponent[key]),
            }))
        .reduce((a, b) => ({...a, ...b}));

const renderChart = (Component, opts) => ({ resultSet, error }) =>
        (resultSet && <Component resultSet={resultSet} opts={opts} />) ||
                (error && error.toString()) || <Spin />;

const ChartRenderer = ({ vizState }) => {
    const {query, chartType} = vizState;
    const component = TypeToMemoChartComponent[chartType];
    const renderProps = useCubeQuery(query);
    return component && renderChart(component, vizState.opts)(renderProps);
};

ChartRenderer.propTypes = {
    vizState: PropTypes.object,
    cubejsApi: PropTypes.object,
};
ChartRenderer.defaultProps = {
    vizState: {},
    cubejsApi: null,
};
export default ChartRenderer;
