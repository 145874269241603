import {
  timeRenderAbdHowLong,
  DashboardUtils,
  userCRMLinkRender,
  usdRender,
  monyRender,
} from "../DashboardUtils";

const dashboard_balance = [
  {
    id: 0,
    lg: 12,
    name: "Баланс подписок и отписок (1 год)",
    vizState: {
      query: {
        measures: ["ImBillingsubScriptionsActions.result"],
        timeDimensions: [
          {
            dimension: "ImBillingsubScriptionsActions.timeAt",
            granularity: "month",
            dateRange: "Last 365 days",
          },
        ],
        filters: [
          {
            dimension: "ImSaasUsers.email",
            operator: "notEquals",
            values: DashboardUtils.demoAndEmptyEmails,
          },
        ],
        order: { "ImBillingsubScriptionsActions.timeAt": "asc" },
      },
      chartType: "line",
    },
  },
  {
    id: 0,
    lg: 12,
    name: "Баланс подписок и отписок (1 год)",
    vizState: {
      query: {
        measures: ["ImBillingsubScriptionsActions.total"],
        timeDimensions: [
          {
            dimension: "ImBillingsubScriptionsActions.timeAt",
            granularity: "month",
            dateRange: "Last 365 days",
          },
        ],
        filters: [
          {
            dimension: "ImSaasUsers.email",
            operator: "notEquals",
            values: DashboardUtils.demoAndEmptyEmails,
          },
        ],
        order: { "ImBillingsubScriptionsActions.timeAt": "asc" },
      },
      chartType: "line",
    },
  },
  {
    id: 0,
    lg: 12,
    name: "Отписки за 90 дней",
    vizState: {
      query: {
        measures: ["ImBillingSubscriptions.total"],
        timeDimensions: [
          {
            dimension: "ImBillingSubscriptions.endsAt",
            dateRange: "Last 90 days",
          },
        ],
        dimensions: [
          "ImSaasUsers.id",
          "ImSaasUsers.email",
          "ImBillingSubscriptions.endsAt",
        ],
        limit: 100,
        order: { "ImBillingSubscriptions.total": "desc" },
        filters: [
          {
            dimension: "ImSaasUsers.email",
            operator: "notEquals",
            values: DashboardUtils.demoAndEmptyEmails,
          },
        ],
      },
      chartType: "table",
      opts: {
        dataMap: (data) => {
          return data;
        },
        columns: (data) => {
          data[1].title = "Email";
          data[1].render = userCRMLinkRender("ImSaasUsers.id");

          data[2].title = "Ends at";
          data[2].render = timeRenderAbdHowLong;

          data[3].title = "Цена";
          data[3].render = usdRender;

          return data.filter((row) => !["ImSaasUsers.id"].includes(row.key));
        },
        dataSource: (data) => {
          return data;
        },
      },
    },
  },
  {
    id: 0,
    lg: 12,
    name: "События за 90 дней",
    vizState: {
      query: {
        measures: [],
        timeDimensions: [
          {
            dimension: "ImBillingsubScriptionsActions.timeAt",
            dateRange: "Last 90 days",
          },
        ],
        filters: [
          {
            dimension: "ImSaasUsers.email",
            operator: "notEquals",
            values: DashboardUtils.demoAndEmptyEmails,
          },
        ],
        dimensions: [
          "ImSaasUsers.id",
          "ImSaasUsers.email",
          "ImBillingsubScriptionsActions.timeAt",
          "ImBillingsubScriptionsActions.amount",
        ],
        filters: [
          {
            dimension: "ImSaasUsers.email",
            operator: "notEquals",
            values: DashboardUtils.demoAndEmptyEmails,
          },
        ],
        order: { "ImBillingsubScriptionsActions.timeAt": "desc" },
      },
      chartType: "table",
      opts: {
        dataMap: (data) => {
          return data;
        },
        columns: (data) => {
          data[1].title = "Email";
          data[1].render = userCRMLinkRender("ImSaasUsers.id");

          data[2].title = "Time";
          data[2].render = timeRenderAbdHowLong;

          data[3].title = "Цена";
          data[3].render = monyRender;

          return data.filter((row) => !["ImSaasUsers.id"].includes(row.key));
        },
        dataSource: (data) => {
          return data;
        },
      },
    },
  },
];

/**
 *
 *
 * График отписок в штуках
 * http://localhost:4000/#/build?query={%22measures%22:[%22ImBillingSubscriptions.count%22],%22timeDimensions%22:[{%22dimension%22:%22ImBillingSubscriptions.startsAt%22,%22granularity%22:%22month%22}],%22order%22:{%22ImBillingSubscriptions.startsAt%22:%22asc%22}}
 *
 * График отписок в долларах
 *
 * http://localhost:4000/#/build?query={%22measures%22:[%22ImBillingSubscriptions.total%22],%22timeDimensions%22:[{%22dimension%22:%22ImBillingSubscriptions.startsAt%22,%22granularity%22:%22month%22}],%22order%22:{%22ImBillingSubscriptions.startsAt%22:%22asc%22}}
 */
// http://imbachat.com:4001/#/build?query={%22measures%22:[%22ImbachatAnalyticsWidgets.count%22],%22timeDimensions%22:[],%22dimensions%22:[%22ImWpData.host%22,%22ImWpData.template%22,%22ImWpData.lang%22],%22order%22:{}}

/**
 * Итого рост
 * http://localhost:4000/#/build?query={%22measures%22:[%22ImBillingsubScriptionsActions.result%22],%22timeDimensions%22:[{%22dimension%22:%22ImBillingsubScriptionsActions.timeAt%22,%22granularity%22:%22month%22,%22dateRange%22:%22Last%20300%20days%22}],%22filters%22:[],%22order%22:{%22ImBillingsubScriptionsActions.timeAt%22:%22asc%22}}
 */

export { dashboard_balance };
