import React from 'react';
import { Col, Tag, Popover } from 'antd';
import {stripeIdRender, authTokenData, hostRender, utmTermRender, DashboardUtils, emailRender, timeRender} from '../DashboardUtils';


const dashboard_ADS = [
{
        lg: 8,
        id: 0,
        name: 'Регистрации (Год)',
        vizState: {
            query: {
		    "timezone": "Asia/Vladivostok","measures":["ImSaasUsers.count"],"timeDimensions":[{"dimension":"ImSaasUsers.createdAt","granularity":"month","dateRange":"Last 365 days"}],"order":{}},
            chartType: 'line',
        },
    },
{
        lg: 8,
        id: 0,
        name: 'Регистрации (6 мес)',
        vizState: {
            query: {
		    "timezone": "Asia/Vladivostok","measures":["ImSaasUsers.count"],"timeDimensions":[{"dimension":"ImSaasUsers.createdAt","granularity":"week","dateRange":"Last 180 days"}],"order":{}},
            chartType: 'line',
        },
    },
{
        lg: 8,
        id: 0,
        name: 'Регистрации (30 дней)',
        vizState: {
            query: {
		    "timezone": "Asia/Vladivostok","measures":["ImSaasUsers.count"],"timeDimensions":[{"dimension":"ImSaasUsers.createdAt","granularity":"day","dateRange":"Last 30 days"}],"order":{}},
            chartType: 'line',
        },
    }, 
    {
        lg: 24,
        id: 0,
        name: 'Отслеженые регистрации (За 14 дней)',
        vizState: {
            query: {
		    "timezone": "Asia/Vladivostok",
                "measures": ["ImReferrals.count"], 
                "timeDimensions": [
                    {"dimension": "ImReferrals.createdAt", "granularity": "day", "dateRange": "Last 30 days"}
                ],
                "filters": [],
                "dimensions": ["ImReferrals.isTracked"], 
                "order": {}
            },
            chartType: 'line',
        },
    },
    {
        lg: 24,
        id: 0,
        name: 'Регистрации (За 1 день)',
        vizState: {
            query: {
		    "timezone": "Asia/Vladivostok",
                "measures":[],
                "timeDimensions":[
                    {"dimension":"ImSaasUsers.createdAt","dateRange":"Last 1 days"}
                ],
                "dimensions": [
                    "ImReferrals.parentUserId",
                    "ImSaasUsers.email",
                    "ImSaasUsers.hasStripeId",
                    "ImReferrals.httpReferer",  
                    "ImReferrals.utmTerm", 
                    "ImReferrals.utmContent", 
                    "ImReferrals.utmMedium",
                    "ImReferrals.utmCampaign",
                    "ImReferrals.utmSource",
                    "ImSaasUsers.createdAt",
                    "ImReferrals.isTracked",
                ],
                "order":{"ImSaasUsers.createdAt":"desc"}
            },
            chartType: 'table',
            opts: {
                dataMap: (data) => {
                    return data
                },
                columns: (data) => { 
                    data[0].title = 'Ref'
                    // data[0].render = hostRender 
                    
                    data[1].title = 'Email'  
                    data[1].render = emailRender
                    data[2].title = 'Card' 
                    data[2].render = stripeIdRender
                     
                    data[3].title = 'http ref'
                    data[3].render = hostRender 
                     
                    data[4].title = 'Term' 
                    data[4].render = utmTermRender
 
                    data[5].title = 'Utm Content' 
                    data[5].render = utmTermRender
 
                    data[6].title = 'Utm Medium' 
                    data[6].render = utmTermRender

                    data[7].title = 'Utm Campaign' 
                    data[7].render = utmTermRender
                    
                    data[8].title = 'Utm Source' 
                    data[8].render = utmTermRender

                    data[9].title = 'Date' 
		    data[9].render = timeRender

                    data[10].title = 'Tracked' 
                    data[10].render = stripeIdRender
                    return data
                },
                dataSource: (data) => {
                    return data
                },
            }
        },
    }, 
    {
        lg: 24,
        id: 0,
        name: 'Регистрации (За 30 дней)',
        vizState: {
            query: {
		    "timezone": "Asia/Vladivostok",
                "measures":[],
                "timeDimensions":[
                    {"dimension":"ImSaasUsers.createdAt","dateRange":"Last 30 days"}
                ],
                "dimensions": [
                    "ImReferrals.parentUserId",
                    "ImSaasUsers.email",
                    "ImSaasUsers.hasStripeId",
                    "ImReferrals.httpReferer",  
                    "ImReferrals.utmTerm", 
                    "ImReferrals.utmContent", 
                    "ImReferrals.utmMedium",
                    "ImReferrals.utmCampaign",
                    "ImReferrals.utmSource",
                    "ImSaasUsers.createdAt",
		    "ImbasynergyImbachatDevelopers.host"
                ],
                "order":{"ImSaasUsers.createdAt":"desc"}
            },
            chartType: 'table',
            opts: {
                dataMap: (data) => {
                    return data
                },
                columns: (data) => { 
                    data[0].title = 'Ref'
                    // data[0].render = hostRender 
                    
                    data[1].title = 'Email'  
                    data[1].render = emailRender
                    data[2].title = 'Card' 
                    data[2].render = stripeIdRender
                     
                    data[3].title = 'http ref'
                    data[3].render = hostRender 
                     
                    data[4].title = 'Term' 
                    data[4].render = utmTermRender
 
                    data[5].title = 'Utm Content' 
                    data[5].render = utmTermRender
 
                    data[6].title = 'Utm Medium' 
                    data[6].render = utmTermRender

                    data[7].title = 'Utm Campaign' 
                    data[7].render = utmTermRender
                    
                    data[8].title = 'Utm Source' 
                    data[8].render = utmTermRender

                    data[9].title = 'Date' 
		    data[9].render = timeRender

                    data[10].title = 'Host' 
                    data[10].render = hostRender

                    return data
                },
                dataSource: (data) => {
                    return data
                },
            }
        },
    }, 
];

  export {dashboard_ADS};
