import React from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import { Layout, Menu } from 'antd';

const Header = ({ location }) => (
  <Layout.Header
    style={{
      padding: '0 32px',
    }}
  >
    <div
      style={{
        float: 'left',
      }}
    >
      <h2
        style={{
          color: '#fff',
          margin: 0,
          marginRight: '1em',
          display: 'inline',
          width: 100,
          lineHeight: '54px',
        }}
      >
        ImbaChat 
      </h2>
    </div>
    <Menu
      theme="dark"
      mode="horizontal"
      selectedKeys={[location.pathname]}
      style={{
        lineHeight: '64px',
      }}
    >
      <Menu.Item key="/">
        <Link to="/">Активность</Link> 
      </Menu.Item>
      <Menu.Item key="/api">  
        <Link to="/api">API</Link> 
      </Menu.Item>
      <Menu.Item key="/sites">   
        <Link to="/sites">Активные интеграции</Link>
      </Menu.Item>
      <Menu.Item key="/widgets">   
        <Link to="/widgets">Виджеты</Link>
      </Menu.Item>
      <Menu.Item key="/langs">   
        <Link to="/langs">Языки</Link>
      </Menu.Item>
      <Menu.Item key="/ads">   
        <Link to="/ads">Регистрации</Link>
      </Menu.Item>
      <Menu.Item key="/payments">   
        <Link to="/payments">Подписки</Link>
      </Menu.Item> 
      <Menu.Item key="/links">   
        <Link to="/links">Ссылки</Link>
      </Menu.Item>
      <Menu.Item key="/wp">   
        <Link to="/wp">WP</Link>
      </Menu.Item>
      {/*<Menu.Item key="/wp-rate">   
        <Link to="/wp-rate">Wp rate</Link>
      </Menu.Item>
      <Menu.Item key="/wp-deactivate">   
        <Link to="/wp-deactivate">Wp deactivate</Link>
    </Menu.Item>*/}
      <Menu.Item key="/buyers">   
        <Link to="/buyers">Подписчики</Link>
      </Menu.Item>
      <Menu.Item key="/kpi">   
        <Link to="/kpi">KPI</Link>
      </Menu.Item>
      <Menu.Item key="/balance">   
        <Link to="/balance">Баланс</Link>
      </Menu.Item>
    </Menu>
  </Layout.Header>
);

export default withRouter(Header);
