
import {
    DashboardUtils,
  } from "../DashboardUtils";
  
  const dashboard_kpi = [
  {
    id: 0,
    lg: 8,
    name: "Количество подписок (1 месяц)",
    vizState: {
      query: {
        measures: ["ImBillingsubScriptionsActions.count"],
        timeDimensions: [
          {
            dimension: "ImBillingsubScriptionsActions.timeAt",
            granularity: "day",
            dateRange: "Last 30 days",
          },
        ],
        filters: [
          {
            dimension: "ImSaasUsers.email",
            operator: "notEquals",
            values: DashboardUtils.demoAndEmptyEmails,
          },
          {
            dimension: "ImBillingsubScriptionsActions.valType",
            operator: "equals",
            values: ["1"],
          },
        ],
        order: { "ImBillingsubScriptionsActions.timeAt": "asc" },
      },
      chartType: "line",
    },
  },
  {
    id: 0,
    lg: 8,
    name: "Количество отписок (1 месяц)",
    vizState: {
      query: {
        measures: ["ImBillingsubScriptionsActions.count"],
        timeDimensions: [
          {
            dimension: "ImBillingsubScriptionsActions.timeAt",
            granularity: "day",
            dateRange: "Last 30 days",
          },
        ],
        filters: [
          {
            dimension: "ImSaasUsers.email",
            operator: "notEquals",
            values: DashboardUtils.demoAndEmptyEmails,
          },
          {
            dimension: "ImBillingsubScriptionsActions.valType",
            operator: "equals",
            values: ["-1"],
          },
        ],
        order: { "ImBillingsubScriptionsActions.timeAt": "asc" },
      },
      chartType: "line",
    },
  },
  {
    id: 0,
    lg: 8,
    name: "Итог (1 месяц)",
    vizState: {
      query: {
        measures: ["ImBillingsubScriptionsActions.result"],
        timeDimensions: [
          {
            dimension: "ImBillingsubScriptionsActions.timeAt",
            // granularity: "day",
            dateRange: "Last 30 days",
          },
        ],
        filters: [
          {
            dimension: "ImSaasUsers.email",
            operator: "notEquals",
            values: DashboardUtils.demoAndEmptyEmails,
          },
        ],
        order: { "ImBillingsubScriptionsActions.timeAt": "asc" },
      },
      chartType: "number",
    },
  },
  {
    id: 0,
    lg: 8,
    name: "Количество подписок (6 месяцев)",
    vizState: {
      query: {
        measures: ["ImBillingsubScriptionsActions.count"],
        timeDimensions: [
          {
            dimension: "ImBillingsubScriptionsActions.timeAt",
            granularity: "week",
            dateRange: "Last 180 days",
          },
        ],
        filters: [
          {
            dimension: "ImSaasUsers.email",
            operator: "notEquals",
            values: DashboardUtils.demoAndEmptyEmails,
          },
          {
            dimension: "ImBillingsubScriptionsActions.valType",
            operator: "equals",
            values: ["1"],
          },
        ],
        order: { "ImBillingsubScriptionsActions.timeAt": "asc" },
      },
      chartType: "line",
    },
  },
  {
    id: 0,
    lg: 8,
    name: "Количество отписок (6 месяцев)",
    vizState: {
      query: {
        measures: ["ImBillingsubScriptionsActions.count"],
        timeDimensions: [
          {
            dimension: "ImBillingsubScriptionsActions.timeAt",
            granularity: "week",
            dateRange: "Last 180 days",
          },
        ],
        filters: [
          {
            dimension: "ImSaasUsers.email",
            operator: "notEquals",
            values: DashboardUtils.demoAndEmptyEmails,
          },
          {
            dimension: "ImBillingsubScriptionsActions.valType",
            operator: "equals",
            values: ["-1"],
          },
        ],
        order: { "ImBillingsubScriptionsActions.timeAt": "asc" },
      },
      chartType: "line",
    },
  },
  {
    id: 0,
    lg: 8,
    name: "Итог (6 месяцев)",
    vizState: {
      query: {
        measures: ["ImBillingsubScriptionsActions.result"],
        timeDimensions: [
          {
            dimension: "ImBillingsubScriptionsActions.timeAt",
            // granularity: "week",
            dateRange: "Last 180 days",
          },
        ],
        filters: [
          {
            dimension: "ImSaasUsers.email",
            operator: "notEquals",
            values: DashboardUtils.demoAndEmptyEmails,
          },
        ],
        order: { "ImBillingsubScriptionsActions.timeAt": "asc" },
      },
      chartType: "number",
    },
  },
  {
    id: 0,
    lg: 8,
    name: "Количество подписок (1 год)",
    vizState: {
      query: {
        measures: ["ImBillingsubScriptionsActions.count"],
        timeDimensions: [
          {
            dimension: "ImBillingsubScriptionsActions.timeAt",
            granularity: "month",
            dateRange: "Last 365 days",
          },
        ],
        filters: [
          {
            dimension: "ImSaasUsers.email",
            operator: "notEquals",
            values: DashboardUtils.demoAndEmptyEmails,
          },
          {
            dimension: "ImBillingsubScriptionsActions.valType",
            operator: "equals",
            values: ["1"],
          },
        ],
        order: { "ImBillingsubScriptionsActions.timeAt": "asc" },
      },
      chartType: "line",
    },
  },
  {
    id: 0,
    lg: 8,
    name: "Количество отписок (1 год)",
    vizState: {
      query: {
        measures: ["ImBillingsubScriptionsActions.count"],
        timeDimensions: [
          {
            dimension: "ImBillingsubScriptionsActions.timeAt",
            granularity: "month",
            dateRange: "Last 365 days",
          },
        ],
        filters: [
          {
            dimension: "ImSaasUsers.email",
            operator: "notEquals",
            values: DashboardUtils.demoAndEmptyEmails,
          },
          {
            dimension: "ImBillingsubScriptionsActions.valType",
            operator: "equals",
            values: ["-1"],
          },
        ],
        order: { "ImBillingsubScriptionsActions.timeAt": "asc" },
      },
      chartType: "line",
    },
  },
  {
    id: 0,
    lg: 8,
    name: "Итог (1 год)",
    vizState: {
      query: {
        measures: ["ImBillingsubScriptionsActions.result"],
        timeDimensions: [
          {
            dimension: "ImBillingsubScriptionsActions.timeAt",
            // granularity: "month",
            dateRange: "Last 365 days",
          },
        ],
        filters: [
          {
            dimension: "ImSaasUsers.email",
            operator: "notEquals",
            values: DashboardUtils.demoAndEmptyEmails,
          },
        ],
        order: { "ImBillingsubScriptionsActions.timeAt": "asc" },
      },
      chartType: "number",
    },
  },
];

export { dashboard_kpi };
