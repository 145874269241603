import React from 'react';
import { Col, Tag, Popover } from 'antd';
import {stripeIdRender, authTokenData, hostRender, utmTermRender, DashboardUtils, timeRender, emailRender} from '../DashboardUtils';

 
let dashboard_wp_rate = [
    {
        id: 0,
        lg: 24,
        name: 'Оценки WP',
        vizState: {
            query: {
  "measures": [
    "ImWpPluginRate.count"
  ],
  "timeDimensions": [
    {
      "dimension": "ImWpPluginRate.createdAt",
      "granularity": "day"
    }
  ],
  "dimensions": [
    "ImWpPluginRate.rating"
  ],
  "order": {},
  "filters": []
},
            chartType: 'bar',
            opts: {
                dataMap: (data) => {
                    return data
                },
                columns: (data) => {  
                    return data
                },
                dataSource: (data) => {
                    return data
                },
            }
        }
    }, 
     
    {
        id: 0,
        lg: 24,
        name: 'Оценки WP',
        vizState: {
            query: {
                    "measures": [],
                    "timeDimensions": [
                      {
                        "dimension": "ImWpPluginRate.createdAt"
                      }
                    ],
                    "dimensions": [
                      "ImWpPluginRate.devId",
                      "ImWpPluginRate.rating",
                      "ImWpPluginRate.additionally",
                      "ImWpPluginRate.createdAt"
                    ],
                    "order": {},
                    "filters": []
                  },
            chartType: 'table',
            opts: {
                dataMap: (data) => {
                    return data
                },
                columns: (data) => {  
                    return data
                },
                dataSource: (data) => {
                    return data
                },
            }
        }
    },
]
  export {dashboard_wp_rate};
