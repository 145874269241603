import React from 'react';
import { Col, Row, Skeleton, Card, Tooltip } from 'antd';
import { SolutionOutlined } from '@ant-design/icons';

export const HeaderBlock = (props) => {
    let HeaderContent = () => {
        return  <div style={{ marginLeft: 12, width: 200 }}>
                    <Skeleton paragraph={{ rows: 0, width: 50 }} size={'small'} active />
                </div>;
    };

    if (props?.info) {
        if (props.info.id) {
            HeaderContent = () => {
                return  <Row style={{ marginLeft: 12, width: '100%'}} justify={'space-between'} align={'middle'}>
                            <Col>
                                <h2 style={{ marginBottom: 0 }}>{props.info.name} {props.info.surname}</h2>
                                <Row><h5>ID {props.info.id}</h5></Row>
                            </Col>
                            <Col>
                                <Tooltip    placement="right"
                                            title="Перейти к пользователю"
                                >
                                    <a href={' https://imbachat.com/backend/rainlab/user/users/preview/' + props.info.id}
                                        target="_blank"
                                    >
                                        <SolutionOutlined style={{ fontSize: 20, marginRight: 24}}/>
                                    </a>
                                </Tooltip>
                            </Col>
                        </Row>;
            };
        } else {
            HeaderContent = () => {
                return  <div style={{ marginLeft: 12 }}>
                            <h2 style={{ marginBottom: 0 }}>Пользователь не найден</h2>
                            <h5>Неверный ID</h5>
                        </div>;
            };
        }
    }

    return <Row>
        <Card size="small" style={{ width: '100%' }}>
            <HeaderContent />
        </Card>
    </Row>;
};
